@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
{
  /** FONTS */
  /** HEADING **/
  /** PARAGRAPH **/
  /** FONTS */
  /** HEADING **/
  /** PARAGRAPH **/
  /** FONTS */
  /** HEADING **/
  /** PARAGRAPH **/
  /** FONTS */
  /** HEADING **/
  /** PARAGRAPH **/
  /** FONTS */
  /** HEADING **/
  /** PARAGRAPH **/ }
  .heading, h1, h2, .navbar .navbar-brand, h3, h4, h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link, h6 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #475F7B; }
  h1 {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.8px; }
    h1.title {
      font-size: 54px;
      line-height: 50px;
      letter-spacing: -1.13684px; }
  h2, .navbar .navbar-brand {
    font-size: 32px;
    line-height: 44px; }
    h2.title, .navbar .title.navbar-brand {
      font-size: 45px;
      line-height: 44px; }
  h3 {
    font-size: 26px;
    line-height: 30px; }
    h3.title {
      font-size: 38px;
      line-height: 30px; }
  h4 {
    font-size: 22px;
    line-height: 26px; }
    h4.title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 2.16667px; }
  h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link {
    font-size: 18px;
    line-height: 21px; }
  h6 {
    font-size: 15px;
    line-height: 18px; }
  p, .text, body {
    color: #596778;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    p.lead, .text.lead, body.lead {
      font-size: 18px;
      line-height: 24px;
      color: #475F7B; }
    p.muted, .text.muted, body.muted {
      color: #828D99; }
    p.tiny, .text.tiny, body.tiny {
      font-size: 12px;
      line-height: 18px; }
  body {
    background-color: #F2F4F4; }
  .nw {
    white-space: nowrap; }
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px; } }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-first {
    order: -1; }
  .order-last {
    order: 13; }
  .order-0 {
    order: 0; }
  .order-1 {
    order: 1; }
  .order-2 {
    order: 2; }
  .order-3 {
    order: 3; }
  .order-4 {
    order: 4; }
  .order-5 {
    order: 5; }
  .order-6 {
    order: 6; }
  .order-7 {
    order: 7; }
  .order-8 {
    order: 8; }
  .order-9 {
    order: 9; }
  .order-10 {
    order: 10; }
  .order-11 {
    order: 11; }
  .order-12 {
    order: 12; }
  .offset-1 {
    margin-left: 8.33333%; }
  .offset-2 {
    margin-left: 16.66667%; }
  .offset-3 {
    margin-left: 25%; }
  .offset-4 {
    margin-left: 33.33333%; }
  .offset-5 {
    margin-left: 41.66667%; }
  .offset-6 {
    margin-left: 50%; }
  .offset-7 {
    margin-left: 58.33333%; }
  .offset-8 {
    margin-left: 66.66667%; }
  .offset-9 {
    margin-left: 75%; }
  .offset-10 {
    margin-left: 83.33333%; }
  .offset-11 {
    margin-left: 91.66667%; }
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-sm-first {
      order: -1; }
    .order-sm-last {
      order: 13; }
    .order-sm-0 {
      order: 0; }
    .order-sm-1 {
      order: 1; }
    .order-sm-2 {
      order: 2; }
    .order-sm-3 {
      order: 3; }
    .order-sm-4 {
      order: 4; }
    .order-sm-5 {
      order: 5; }
    .order-sm-6 {
      order: 6; }
    .order-sm-7 {
      order: 7; }
    .order-sm-8 {
      order: 8; }
    .order-sm-9 {
      order: 9; }
    .order-sm-10 {
      order: 10; }
    .order-sm-11 {
      order: 11; }
    .order-sm-12 {
      order: 12; }
    .offset-sm-0 {
      margin-left: 0; }
    .offset-sm-1 {
      margin-left: 8.33333%; }
    .offset-sm-2 {
      margin-left: 16.66667%; }
    .offset-sm-3 {
      margin-left: 25%; }
    .offset-sm-4 {
      margin-left: 33.33333%; }
    .offset-sm-5 {
      margin-left: 41.66667%; }
    .offset-sm-6 {
      margin-left: 50%; }
    .offset-sm-7 {
      margin-left: 58.33333%; }
    .offset-sm-8 {
      margin-left: 66.66667%; }
    .offset-sm-9 {
      margin-left: 75%; }
    .offset-sm-10 {
      margin-left: 83.33333%; }
    .offset-sm-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-md-first {
      order: -1; }
    .order-md-last {
      order: 13; }
    .order-md-0 {
      order: 0; }
    .order-md-1 {
      order: 1; }
    .order-md-2 {
      order: 2; }
    .order-md-3 {
      order: 3; }
    .order-md-4 {
      order: 4; }
    .order-md-5 {
      order: 5; }
    .order-md-6 {
      order: 6; }
    .order-md-7 {
      order: 7; }
    .order-md-8 {
      order: 8; }
    .order-md-9 {
      order: 9; }
    .order-md-10 {
      order: 10; }
    .order-md-11 {
      order: 11; }
    .order-md-12 {
      order: 12; }
    .offset-md-0 {
      margin-left: 0; }
    .offset-md-1 {
      margin-left: 8.33333%; }
    .offset-md-2 {
      margin-left: 16.66667%; }
    .offset-md-3 {
      margin-left: 25%; }
    .offset-md-4 {
      margin-left: 33.33333%; }
    .offset-md-5 {
      margin-left: 41.66667%; }
    .offset-md-6 {
      margin-left: 50%; }
    .offset-md-7 {
      margin-left: 58.33333%; }
    .offset-md-8 {
      margin-left: 66.66667%; }
    .offset-md-9 {
      margin-left: 75%; }
    .offset-md-10 {
      margin-left: 83.33333%; }
    .offset-md-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-lg-first {
      order: -1; }
    .order-lg-last {
      order: 13; }
    .order-lg-0 {
      order: 0; }
    .order-lg-1 {
      order: 1; }
    .order-lg-2 {
      order: 2; }
    .order-lg-3 {
      order: 3; }
    .order-lg-4 {
      order: 4; }
    .order-lg-5 {
      order: 5; }
    .order-lg-6 {
      order: 6; }
    .order-lg-7 {
      order: 7; }
    .order-lg-8 {
      order: 8; }
    .order-lg-9 {
      order: 9; }
    .order-lg-10 {
      order: 10; }
    .order-lg-11 {
      order: 11; }
    .order-lg-12 {
      order: 12; }
    .offset-lg-0 {
      margin-left: 0; }
    .offset-lg-1 {
      margin-left: 8.33333%; }
    .offset-lg-2 {
      margin-left: 16.66667%; }
    .offset-lg-3 {
      margin-left: 25%; }
    .offset-lg-4 {
      margin-left: 33.33333%; }
    .offset-lg-5 {
      margin-left: 41.66667%; }
    .offset-lg-6 {
      margin-left: 50%; }
    .offset-lg-7 {
      margin-left: 58.33333%; }
    .offset-lg-8 {
      margin-left: 66.66667%; }
    .offset-lg-9 {
      margin-left: 75%; }
    .offset-lg-10 {
      margin-left: 83.33333%; }
    .offset-lg-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-xl-first {
      order: -1; }
    .order-xl-last {
      order: 13; }
    .order-xl-0 {
      order: 0; }
    .order-xl-1 {
      order: 1; }
    .order-xl-2 {
      order: 2; }
    .order-xl-3 {
      order: 3; }
    .order-xl-4 {
      order: 4; }
    .order-xl-5 {
      order: 5; }
    .order-xl-6 {
      order: 6; }
    .order-xl-7 {
      order: 7; }
    .order-xl-8 {
      order: 8; }
    .order-xl-9 {
      order: 9; }
    .order-xl-10 {
      order: 10; }
    .order-xl-11 {
      order: 11; }
    .order-xl-12 {
      order: 12; }
    .offset-xl-0 {
      margin-left: 0; }
    .offset-xl-1 {
      margin-left: 8.33333%; }
    .offset-xl-2 {
      margin-left: 16.66667%; }
    .offset-xl-3 {
      margin-left: 25%; }
    .offset-xl-4 {
      margin-left: 33.33333%; }
    .offset-xl-5 {
      margin-left: 41.66667%; }
    .offset-xl-6 {
      margin-left: 50%; }
    .offset-xl-7 {
      margin-left: 58.33333%; }
    .offset-xl-8 {
      margin-left: 66.66667%; }
    .offset-xl-9 {
      margin-left: 75%; }
    .offset-xl-10 {
      margin-left: 83.33333%; }
    .offset-xl-11 {
      margin-left: 91.66667%; } }
  .m-0 {
    margin: 0 !important; }
  .mt-0,
  .my-0 {
    margin-top: 0 !important; }
  .mr-0,
  .mx-0 {
    margin-right: 0 !important; }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important; }
  .ml-0,
  .mx-0 {
    margin-left: 0 !important; }
  .m-1 {
    margin: 0.25rem !important; }
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important; }
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important; }
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important; }
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important; }
  .m-2 {
    margin: 0.5rem !important; }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important; }
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important; }
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important; }
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important; }
  .m-3 {
    margin: 1rem !important; }
  .mt-3,
  .my-3 {
    margin-top: 1rem !important; }
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important; }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important; }
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important; }
  .m-4 {
    margin: 1.5rem !important; }
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important; }
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important; }
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important; }
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important; }
  .m-5 {
    margin: 3rem !important; }
  .mt-5,
  .my-5 {
    margin-top: 3rem !important; }
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important; }
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important; }
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important; }
  .p-0 {
    padding: 0 !important; }
  .pt-0,
  .py-0 {
    padding-top: 0 !important; }
  .pr-0,
  .px-0 {
    padding-right: 0 !important; }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important; }
  .pl-0,
  .px-0 {
    padding-left: 0 !important; }
  .p-1 {
    padding: 0.25rem !important; }
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important; }
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important; }
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important; }
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important; }
  .p-2 {
    padding: 0.5rem !important; }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important; }
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important; }
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important; }
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important; }
  .p-3 {
    padding: 1rem !important; }
  .pt-3,
  .py-3 {
    padding-top: 1rem !important; }
  .pr-3,
  .px-3 {
    padding-right: 1rem !important; }
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important; }
  .pl-3,
  .px-3 {
    padding-left: 1rem !important; }
  .p-4 {
    padding: 1.5rem !important; }
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important; }
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important; }
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important; }
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important; }
  .p-5 {
    padding: 3rem !important; }
  .pt-5,
  .py-5 {
    padding-top: 3rem !important; }
  .pr-5,
  .px-5 {
    padding-right: 3rem !important; }
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important; }
  .pl-5,
  .px-5 {
    padding-left: 3rem !important; }
  .m-n1 {
    margin: -0.25rem !important; }
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important; }
  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important; }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important; }
  .m-n2 {
    margin: -0.5rem !important; }
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important; }
  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important; }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important; }
  .m-n3 {
    margin: -1rem !important; }
  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important; }
  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important; }
  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important; }
  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important; }
  .m-n4 {
    margin: -1.5rem !important; }
  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important; }
  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important; }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important; }
  .m-n5 {
    margin: -3rem !important; }
  .mt-n5,
  .my-n5 {
    margin-top: -3rem !important; }
  .mr-n5,
  .mx-n5 {
    margin-right: -3rem !important; }
  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem !important; }
  .ml-n5,
  .mx-n5 {
    margin-left: -3rem !important; }
  .m-auto {
    margin: auto !important; }
  .mt-auto,
  .my-auto {
    margin-top: auto !important; }
  .mr-auto,
  .mx-auto {
    margin-right: auto !important; }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important; }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important; }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important; }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important; }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important; }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important; }
    .m-sm-1 {
      margin: 0.25rem !important; }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important; }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important; }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important; }
    .m-sm-2 {
      margin: 0.5rem !important; }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important; }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important; }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important; }
    .m-sm-3 {
      margin: 1rem !important; }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important; }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important; }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important; }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important; }
    .m-sm-4 {
      margin: 1.5rem !important; }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important; }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important; }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important; }
    .m-sm-5 {
      margin: 3rem !important; }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important; }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important; }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important; }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important; }
    .p-sm-0 {
      padding: 0 !important; }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important; }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important; }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important; }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important; }
    .p-sm-1 {
      padding: 0.25rem !important; }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important; }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important; }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important; }
    .p-sm-2 {
      padding: 0.5rem !important; }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important; }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important; }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important; }
    .p-sm-3 {
      padding: 1rem !important; }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important; }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important; }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important; }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important; }
    .p-sm-4 {
      padding: 1.5rem !important; }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important; }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important; }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important; }
    .p-sm-5 {
      padding: 3rem !important; }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important; }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important; }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important; }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important; }
    .m-sm-n1 {
      margin: -0.25rem !important; }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -0.25rem !important; }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -0.25rem !important; }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -0.25rem !important; }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -0.25rem !important; }
    .m-sm-n2 {
      margin: -0.5rem !important; }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -0.5rem !important; }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -0.5rem !important; }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -0.5rem !important; }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -0.5rem !important; }
    .m-sm-n3 {
      margin: -1rem !important; }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem !important; }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem !important; }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem !important; }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem !important; }
    .m-sm-n4 {
      margin: -1.5rem !important; }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem !important; }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem !important; }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem !important; }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem !important; }
    .m-sm-n5 {
      margin: -3rem !important; }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -3rem !important; }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -3rem !important; }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -3rem !important; }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -3rem !important; }
    .m-sm-auto {
      margin: auto !important; }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important; }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important; }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important; }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important; }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important; }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important; }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important; }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important; }
    .m-md-1 {
      margin: 0.25rem !important; }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important; }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important; }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important; }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important; }
    .m-md-2 {
      margin: 0.5rem !important; }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important; }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important; }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important; }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important; }
    .m-md-3 {
      margin: 1rem !important; }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important; }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important; }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important; }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important; }
    .m-md-4 {
      margin: 1.5rem !important; }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important; }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important; }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important; }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important; }
    .m-md-5 {
      margin: 3rem !important; }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important; }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important; }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important; }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important; }
    .p-md-0 {
      padding: 0 !important; }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important; }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important; }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important; }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important; }
    .p-md-1 {
      padding: 0.25rem !important; }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important; }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important; }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important; }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important; }
    .p-md-2 {
      padding: 0.5rem !important; }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important; }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important; }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important; }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important; }
    .p-md-3 {
      padding: 1rem !important; }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important; }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important; }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important; }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important; }
    .p-md-4 {
      padding: 1.5rem !important; }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important; }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important; }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important; }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important; }
    .p-md-5 {
      padding: 3rem !important; }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important; }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important; }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important; }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important; }
    .m-md-n1 {
      margin: -0.25rem !important; }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -0.25rem !important; }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -0.25rem !important; }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -0.25rem !important; }
    .m-md-n2 {
      margin: -0.5rem !important; }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -0.5rem !important; }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -0.5rem !important; }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -0.5rem !important; }
    .m-md-n3 {
      margin: -1rem !important; }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem !important; }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem !important; }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem !important; }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem !important; }
    .m-md-n4 {
      margin: -1.5rem !important; }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem !important; }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem !important; }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem !important; }
    .m-md-n5 {
      margin: -3rem !important; }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -3rem !important; }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -3rem !important; }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -3rem !important; }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -3rem !important; }
    .m-md-auto {
      margin: auto !important; }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important; }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important; }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important; }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important; }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important; }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important; }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important; }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important; }
    .m-lg-1 {
      margin: 0.25rem !important; }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important; }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .m-lg-2 {
      margin: 0.5rem !important; }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important; }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .m-lg-3 {
      margin: 1rem !important; }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important; }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important; }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important; }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important; }
    .m-lg-4 {
      margin: 1.5rem !important; }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important; }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .m-lg-5 {
      margin: 3rem !important; }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important; }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important; }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important; }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important; }
    .p-lg-0 {
      padding: 0 !important; }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important; }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important; }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important; }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important; }
    .p-lg-1 {
      padding: 0.25rem !important; }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important; }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important; }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important; }
    .p-lg-2 {
      padding: 0.5rem !important; }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important; }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important; }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important; }
    .p-lg-3 {
      padding: 1rem !important; }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important; }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important; }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important; }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important; }
    .p-lg-4 {
      padding: 1.5rem !important; }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important; }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important; }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important; }
    .p-lg-5 {
      padding: 3rem !important; }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important; }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important; }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important; }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important; }
    .m-lg-n1 {
      margin: -0.25rem !important; }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -0.25rem !important; }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    .m-lg-n2 {
      margin: -0.5rem !important; }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -0.5rem !important; }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    .m-lg-n3 {
      margin: -1rem !important; }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem !important; }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem !important; }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem !important; }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem !important; }
    .m-lg-n4 {
      margin: -1.5rem !important; }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem !important; }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    .m-lg-n5 {
      margin: -3rem !important; }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -3rem !important; }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -3rem !important; }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -3rem !important; }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -3rem !important; }
    .m-lg-auto {
      margin: auto !important; }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important; }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important; }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important; }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important; }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important; }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important; }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important; }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important; }
    .m-xl-1 {
      margin: 0.25rem !important; }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important; }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .m-xl-2 {
      margin: 0.5rem !important; }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important; }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .m-xl-3 {
      margin: 1rem !important; }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important; }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important; }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important; }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important; }
    .m-xl-4 {
      margin: 1.5rem !important; }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important; }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .m-xl-5 {
      margin: 3rem !important; }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important; }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important; }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important; }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important; }
    .p-xl-0 {
      padding: 0 !important; }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important; }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important; }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important; }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important; }
    .p-xl-1 {
      padding: 0.25rem !important; }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important; }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important; }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important; }
    .p-xl-2 {
      padding: 0.5rem !important; }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important; }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important; }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important; }
    .p-xl-3 {
      padding: 1rem !important; }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important; }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important; }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important; }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important; }
    .p-xl-4 {
      padding: 1.5rem !important; }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important; }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important; }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important; }
    .p-xl-5 {
      padding: 3rem !important; }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important; }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important; }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important; }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important; }
    .m-xl-n1 {
      margin: -0.25rem !important; }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -0.25rem !important; }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    .m-xl-n2 {
      margin: -0.5rem !important; }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -0.5rem !important; }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    .m-xl-n3 {
      margin: -1rem !important; }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem !important; }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem !important; }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem !important; }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem !important; }
    .m-xl-n4 {
      margin: -1.5rem !important; }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem !important; }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    .m-xl-n5 {
      margin: -3rem !important; }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -3rem !important; }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -3rem !important; }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -3rem !important; }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -3rem !important; }
    .m-xl-auto {
      margin: auto !important; }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important; }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important; }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important; }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important; } }
  .mt-n20vh {
    margin-top: -20vh; }
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative; }
  .dropdown-toggle {
    white-space: nowrap; }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    .dropdown-toggle:empty::after {
      margin-left: 0; }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }
  .dropdown-menu-left {
    right: auto;
    left: 0; }
  .dropdown-menu-right {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0; }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0; }
    .dropdown-menu-md-right {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0; }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0; }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto; } }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  .dropleft .dropdown-toggle::after {
    display: none; }
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    .dropdown-item:hover, .dropdown-item:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #F2F4F4; }
    .dropdown-item.active, .dropdown-item:active {
      text-decoration: none;
      background-color: #F2F4F4; }
    .dropdown-item.disabled, .dropdown-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: transparent; }
  .dropdown-menu.show {
    display: block; }
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    color: #6c757d;
    white-space: nowrap; }
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529; }

@keyframes _3WdQS {
  to {
    transform: rotate(360deg); } }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%; }
    .spinner-border {
      animation: _3WdQS .75s linear infinite; }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@keyframes _1RURq {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0; }
    .spinner-grow {
      animation: _1RURq .75s linear infinite; }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  .heading, h1, h2, .navbar .navbar-brand, h3, h4, h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link, h6 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #475F7B; }
  h1 {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.8px; }
    h1.title {
      font-size: 54px;
      line-height: 50px;
      letter-spacing: -1.13684px; }
  h2, .navbar .navbar-brand {
    font-size: 32px;
    line-height: 44px; }
    h2.title, .navbar .title.navbar-brand {
      font-size: 45px;
      line-height: 44px; }
  h3 {
    font-size: 26px;
    line-height: 30px; }
    h3.title {
      font-size: 38px;
      line-height: 30px; }
  h4 {
    font-size: 22px;
    line-height: 26px; }
    h4.title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 2.16667px; }
  h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link {
    font-size: 18px;
    line-height: 21px; }
  h6 {
    font-size: 15px;
    line-height: 18px; }
  p, .text, body {
    color: #596778;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    p.lead, .text.lead, body.lead {
      font-size: 18px;
      line-height: 24px;
      color: #475F7B; }
    p.muted, .text.muted, body.muted {
      color: #828D99; }
    p.tiny, .text.tiny, body.tiny {
      font-size: 12px;
      line-height: 18px; }
  body {
    background-color: #F2F4F4; }
  .nw {
    white-space: nowrap; }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #FFF;
    border-radius: 4px;
    -webkit-filter: drop-shadow(-4px 6px 9px rgba(25, 42, 70, 0.06));
            filter: drop-shadow(-4px 6px 9px rgba(25, 42, 70, 0.06));
    border: 0.5px solid #C7CFD6;
    margin-bottom: 40px; }
    .card > .card-header {
      padding: 25px 29px 10px; }
    .card > .card-footer {
      padding: 10px 29px 25px; }
    .card > .card-body {
      padding: 9px 29px; }
      .card > .card-body > form > .form-group:first-child {
        margin-top: 0; }
  .card-table > .card-body {
    padding: 9px 19px; }
  .card-table > .card-footer {
    padding: 15px 19px 15px;
    background: #F2F4F4; }
  .card-table > .card-header {
    padding: 25px 19px 10px; }
    .card-table > .card-header.search input, .card-table > .card-header.search select {
      width: 100%;
      height: 38px;
      border: 0.5px solid #C7CFD6;
      border-radius: 4px;
      font-family: "Rubik", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #475F7B;
      padding: 0 12px; }
    .card-table > .card-header.search input:-ms-input-placeholder {
      font-family: "IBM Plex Sans", sans-serif;
      color: #828D99; }
    .card-table > .card-header.search input::-webkit-input-placeholder {
      font-family: "IBM Plex Sans", sans-serif;
      color: #828D99; }
    .card-table > .card-header.search input::placeholder {
      font-family: "IBM Plex Sans", sans-serif;
      color: #828D99; }
    .card-table > .card-header.search .search-input {
      background-image: url("data:image/svg+xml;utf8,<svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 9L13 14' stroke='%23475F7B' stroke-linecap='square'/><path fill-rule='evenodd' clip-rule='evenodd' d='M8.53539 9.53539C6.58257 11.4882 3.41743 11.4882 1.46461 9.53539C-0.488205 7.58257 -0.488205 4.41743 1.46461 2.46461C3.41743 0.511795 6.58257 0.511795 8.53539 2.46461C10.4882 4.42056 10.4882 7.58569 8.53539 9.53539Z' stroke='%23475F7B'/><path opacity='0.01' d='M13 1L0 13' stroke='%23475F7B' stroke-linecap='square'/></svg>");
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 30px; }
  .form-group {
    margin: 15px 0; }
    .form-group > .form-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
      display: block; }
    .form-group > .form-control {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      border: 1px solid #C7CFD6;
      border-radius: 4px;
      display: block;
      width: 100%;
      vertical-align: middle;
      height: 36px;
      padding: 7px 12px; }
    .form-group:-ms-input-placeholder {
      color: #727E8C;
      opacity: 1; }
    .form-group::-webkit-input-placeholder {
      color: #727E8C;
      opacity: 1; }
    .form-group::placeholder {
      color: #727E8C;
      opacity: 1; }
    .form-group:disabled, .form-group[readonly] {
      background-color: #F2F4F4;
      opacity: 1; }
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
  .form-file,
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%; }
  select.form-control[size], select.form-control[multiple] {
    height: auto; }
  textarea.form-control {
    height: auto !important; }
  .form-row, .form-group.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
    .form-row > .col, .form-group.row > .col,
    .form-row > [class*="col-"],
    .form-group.row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; }
    .form-row .form-group .row .col .form-group, .form-group.row .form-group .row .col .form-group {
      margin: 0; }
  .btn {
    width: auto;
    min-width: 100px;
    height: 38px;
    padding: 3px 12px 6px;
    background-color: #5A8DEE;
    border: 1px solid #5A8DEE;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    color: #FFF;
    cursor: pointer; }
    .btn:hover {
      background-color: #2c6de9 !important;
      border: 1px solid #2c6de9 !important; }
    .btn:focus, .btn.focus {
      outline: 0; }
    .btn.btn-link {
      background-color: transparent !important;
      border: 1px solid transparent !important;
      color: #475F7B; }
    .btn.btn-primary {
      background-color: #5A8DEE !important;
      border: 1px solid #5A8DEE !important; }
      .btn.btn-primary:hover {
        background-color: #2c6de9 !important;
        border: 1px solid #2c6de9 !important; }
    .btn.btn-outline-primary {
      background-color: #fff !important;
      border: 1px solid #5A8DEE !important;
      color: #5A8DEE; }
      .btn.btn-outline-primary:hover {
        background-color: #2c6de9 !important;
        border: 1px solid #2c6de9 !important;
        color: #FFF; }
    .btn.btn-secondary {
      background-color: #475F7B !important;
      border: 1px solid #475F7B !important; }
      .btn.btn-secondary:hover {
        background-color: #34465b !important;
        border: 1px solid #34465b !important; }
    .btn.btn-outline-secondary {
      background-color: #fff !important;
      border: 1px solid #475F7B !important;
      color: #475F7B; }
      .btn.btn-outline-secondary:hover {
        background-color: #34465b !important;
        border: 1px solid #34465b !important;
        color: #FFF; }
    .btn.btn-success {
      background-color: #39DA8A !important;
      border: 1px solid #39DA8A !important; }
      .btn.btn-success:hover {
        background-color: #23bd70 !important;
        border: 1px solid #23bd70 !important; }
    .btn.btn-outline-success {
      background-color: #fff !important;
      border: 1px solid #39DA8A !important;
      color: #39DA8A; }
      .btn.btn-outline-success:hover {
        background-color: #23bd70 !important;
        border: 1px solid #23bd70 !important;
        color: #FFF; }
    .btn.btn-info {
      background-color: #00CFDD !important;
      border: 1px solid #00CFDD !important; }
      .btn.btn-info:hover {
        background-color: #009faa !important;
        border: 1px solid #009faa !important; }
    .btn.btn-outline-info {
      background-color: #fff !important;
      border: 1px solid #00CFDD !important;
      color: #00CFDD; }
      .btn.btn-outline-info:hover {
        background-color: #009faa !important;
        border: 1px solid #009faa !important;
        color: #FFF; }
    .btn.btn-warning {
      background-color: #FDAC41 !important;
      border: 1px solid #FDAC41 !important; }
      .btn.btn-warning:hover {
        background-color: #fc960f !important;
        border: 1px solid #fc960f !important; }
    .btn.btn-outline-warning {
      background-color: #fff !important;
      border: 1px solid #FDAC41 !important;
      color: #FDAC41; }
      .btn.btn-outline-warning:hover {
        background-color: #fc960f !important;
        border: 1px solid #fc960f !important;
        color: #FFF; }
    .btn.btn-danger {
      background-color: #FF5B5C !important;
      border: 1px solid #FF5B5C !important; }
      .btn.btn-danger:hover {
        background-color: #ff2829 !important;
        border: 1px solid #ff2829 !important; }
    .btn.btn-outline-danger {
      background-color: #fff !important;
      border: 1px solid #FF5B5C !important;
      color: #FF5B5C; }
      .btn.btn-outline-danger:hover {
        background-color: #ff2829 !important;
        border: 1px solid #ff2829 !important;
        color: #FFF; }
    .btn.btn-light {
      background-color: #B3C0CE !important;
      border: 1px solid #B3C0CE !important; }
      .btn.btn-light:hover {
        background-color: #94a6ba !important;
        border: 1px solid #94a6ba !important; }
    .btn.btn-outline-light {
      background-color: #fff !important;
      border: 1px solid #B3C0CE !important;
      color: #B3C0CE; }
      .btn.btn-outline-light:hover {
        background-color: #94a6ba !important;
        border: 1px solid #94a6ba !important;
        color: #FFF; }
    .btn.btn-dark {
      background-color: #333 !important;
      border: 1px solid #333 !important; }
      .btn.btn-dark:hover {
        background-color: #1a1a1a !important;
        border: 1px solid #1a1a1a !important; }
    .btn.btn-outline-dark {
      background-color: #fff !important;
      border: 1px solid #333 !important;
      color: #333; }
      .btn.btn-outline-dark:hover {
        background-color: #1a1a1a !important;
        border: 1px solid #1a1a1a !important;
        color: #FFF; }
  .btn-group-vertical .btn {
    margin-right: 5px;
    margin-left: 5px; }
  .btn-group-vertical .btn:first-child {
    margin-left: 0; }
  .btn-group-vertical .btn:last-child {
    margin-right: 0; }
  .text-justify {
    text-align: justify !important; }
  .text-wrap {
    white-space: normal !important; }
  .text-nowrap, .table-responsive td, .table-responsive th {
    white-space: nowrap !important; }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .text-left {
    text-align: left !important; }
  .text-right {
    text-align: right !important; }
  .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important; }
    .text-sm-right {
      text-align: right !important; }
    .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important; }
    .text-md-right {
      text-align: right !important; }
    .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important; }
    .text-lg-right {
      text-align: right !important; }
    .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important; }
    .text-xl-right {
      text-align: right !important; }
    .text-xl-center {
      text-align: center !important; } }
  .text-lowercase {
    text-transform: lowercase !important; }
  .text-uppercase, .table thead th {
    text-transform: uppercase !important; }
  .text-capitalize {
    text-transform: capitalize !important; }
  .text-decoration-none {
    text-decoration: none !important; }
  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important; }
  .text-white {
    color: #FFF !important; }
  .text-primary {
    color: #5A8DEE !important; }
  .text-secondary {
    color: #475F7B !important; }
  .text-success {
    color: #39DA8A !important; }
  .text-info {
    color: #00CFDD !important; }
  .text-warning {
    color: #FDAC41 !important; }
  .text-danger {
    color: #FF5B5C !important; }
  .text-light {
    color: #B3C0CE !important; }
  .text-dark {
    color: #333 !important; }
  .heading, h1, h2, .navbar .navbar-brand, h3, h4, h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link, h6 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #475F7B; }
  h1 {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.8px; }
    h1.title {
      font-size: 54px;
      line-height: 50px;
      letter-spacing: -1.13684px; }
  h2, .navbar .navbar-brand {
    font-size: 32px;
    line-height: 44px; }
    h2.title, .navbar .title.navbar-brand {
      font-size: 45px;
      line-height: 44px; }
  h3 {
    font-size: 26px;
    line-height: 30px; }
    h3.title {
      font-size: 38px;
      line-height: 30px; }
  h4 {
    font-size: 22px;
    line-height: 26px; }
    h4.title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 2.16667px; }
  h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link {
    font-size: 18px;
    line-height: 21px; }
  h6 {
    font-size: 15px;
    line-height: 18px; }
  p, .text, body {
    color: #596778;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    p.lead, .text.lead, body.lead {
      font-size: 18px;
      line-height: 24px;
      color: #475F7B; }
    p.muted, .text.muted, body.muted {
      color: #828D99; }
    p.tiny, .text.tiny, body.tiny {
      font-size: 12px;
      line-height: 18px; }
  body {
    background-color: #F2F4F4; }
  .nw {
    white-space: nowrap; }
  .table {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-spacing: 2px;
    border-collapse: collapse;
    background-color: #FFF; }
    .table th,
    .table td {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #727E8C;
      text-align: left;
      padding: 15px 5px;
      vertical-align: middle;
      border-top: 1px solid #F2F4F4; }
      .table th.link,
      .table td.link {
        font-weight: 500;
        color: #5A8DEE !important;
        cursor: pointer; }
      .table th.small,
      .table td.small {
        font-size: 12px;
        line-height: 18px;
        color: #828D99; }
      .table th.id,
      .table td.id {
        font-size: 12px;
        line-height: 18px;
        color: #828D99; }
    .table thead th {
      padding: 15px 5px;
      font-family: "Rubik", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #475F7B !important;
      vertical-align: bottom;
      border-bottom: 2px solid #F2F4F4; }
      .table thead th.id {
        font-size: 15px;
        line-height: 18px; }
    .table tbody + tbody {
      border-top: 2px solid #F2F4F4; }
  .table-sm th,
  .table-sm td {
    padding: 8px 5px; }
  .table-bordered {
    border: 1px solid #F2F4F4; }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #F2F4F4; }
    .table-bordered thead th,
    .table-bordered thead td {
      border-bottom-width: 2px; }
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0; }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #FAFCFD; }
  .table-hover tbody tr:hover {
    background-color: #E7EDF3; }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-sm > .table-bordered {
        border: 0; } }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-md > .table-bordered {
        border: 0; } }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-lg > .table-bordered {
        border: 0; } }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-xl > .table-bordered {
        border: 0; } }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive > .table-bordered {
      border: 0; }
  .dbl {
    display: block; }
  .w-100p {
    width: 100%; }
  .nav {
    display: flex; }
  a {
    color: #475F7B; }
    a:hover {
      text-decoration: none; }
  .heading, h1, h2, .navbar .navbar-brand, h3, h4, h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link, h6 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #475F7B; }
  h1 {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.8px; }
    h1.title {
      font-size: 54px;
      line-height: 50px;
      letter-spacing: -1.13684px; }
  h2, .navbar .navbar-brand {
    font-size: 32px;
    line-height: 44px; }
    h2.title, .navbar .title.navbar-brand {
      font-size: 45px;
      line-height: 44px; }
  h3 {
    font-size: 26px;
    line-height: 30px; }
    h3.title {
      font-size: 38px;
      line-height: 30px; }
  h4 {
    font-size: 22px;
    line-height: 26px; }
    h4.title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 2.16667px; }
  h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link {
    font-size: 18px;
    line-height: 21px; }
  h6 {
    font-size: 15px;
    line-height: 18px; }
  p, .text, body {
    color: #596778;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    p.lead, .text.lead, body.lead {
      font-size: 18px;
      line-height: 24px;
      color: #475F7B; }
    p.muted, .text.muted, body.muted {
      color: #828D99; }
    p.tiny, .text.tiny, body.tiny {
      font-size: 12px;
      line-height: 18px; }
  body {
    background-color: #F2F4F4; }
  .nw {
    white-space: nowrap; }
  .navbar {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E7EDF3; }
    .navbar .navbar-brand {
      text-decoration: none;
      font-family: "Source Code Pro", sans-serif;
      font-weight: 200; }
    .navbar .nav-link {
      padding: 21px 25px;
      text-decoration: none; }
      .navbar .nav-link:hover, .navbar .nav-link.active {
        background-color: #F2F4F4; }
    .navbar .nav-item {
      display: flex;
      align-items: center;
      padding: 0 25px; }
      .navbar .nav-item:hover, .navbar .nav-item.active {
        background-color: #F2F4F4; }
  .v-center {
    display: flex;
    align-items: center; }
  .v-end {
    display: flex;
    align-items: flex-end; }
  .v-start {
    display: flex;
    align-items: flex-start; }
  .h-center {
    display: flex;
    justify-content: center; }
  .h-end {
    display: flex;
    justify-content: flex-end; }
  .h-start {
    display: flex;
    justify-content: flex-start; }
  .full-height {
    height: 100vh; }
  .heading, h1, h2, .navbar .navbar-brand, h3, h4, h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link, h6 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #475F7B; }
  h1 {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.8px; }
    h1.title {
      font-size: 54px;
      line-height: 50px;
      letter-spacing: -1.13684px; }
  h2, .navbar .navbar-brand {
    font-size: 32px;
    line-height: 44px; }
    h2.title, .navbar .title.navbar-brand {
      font-size: 45px;
      line-height: 44px; }
  h3 {
    font-size: 26px;
    line-height: 30px; }
    h3.title {
      font-size: 38px;
      line-height: 30px; }
  h4 {
    font-size: 22px;
    line-height: 26px; }
    h4.title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 2.16667px; }
  h5, .card > .card-header, .card > .card-footer, .navbar .nav-link, .navbar .nav-item .btn-link {
    font-size: 18px;
    line-height: 21px; }
  h6 {
    font-size: 15px;
    line-height: 18px; }
  p, .text, body {
    color: #596778;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    p.lead, .text.lead, body.lead {
      font-size: 18px;
      line-height: 24px;
      color: #475F7B; }
    p.muted, .text.muted, body.muted {
      color: #828D99; }
    p.tiny, .text.tiny, body.tiny {
      font-size: 12px;
      line-height: 18px; }
  body {
    background-color: #F2F4F4; }
  .nw {
    white-space: nowrap; }
  .paging button {
    cursor: pointer;
    width: 34px;
    height: 34px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3F3356;
    background: transparent; }
    .paging button.prev, .paging button.next {
      border: 1px solid #E2E2E2;
      border-radius: 4px; }
    .paging button.prev {
      margin-right: 6px; }
    .paging button.next {
      margin-left: 6px; }
    .paging button:focus, .paging button.focus {
      outline: 0; }
    .paging button:hover {
      background: #FFF; }
  .paging .paging-group {
    display: inline-block; }
    .paging .paging-group button {
      border-top: 1px solid #E2E2E2;
      border-bottom: 1px solid #E2E2E2;
      border-left: 0 solid transparent;
      border-right: 0 solid transparent; }
    .paging .paging-group button:first-child {
      border-left: 1px solid #E2E2E2;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .paging .paging-group button:last-child {
      border-right: 1px solid #E2E2E2;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
    .paging .paging-group button.active {
      background: #5A8DEE;
      border-radius: 4px;
      border: 1px solid #5A8DEE;
      color: #FFFFFF; }
  * {
    box-sizing: border-box; }

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */
